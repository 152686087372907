import PropTypes from 'prop-types';
import Tooltip from '@vooban/tooltip';
import Moment from 'moment';
import React from 'react';
import { momentObj } from 'react-moment-proptypes';
import { createTimelineLanes } from './helpers/calendarHelpers';
import Project from './models/Project';
import { getMomentExcludingMidnight } from './helpers/momentHelpers';
import { daysInWeek, projectTypeBarge, projectTypeVessel } from './constants';
import LaborAvailabilityProjectTooltipContent from './LaborAvailabilityProjectTooltipContent';

export default class LaborAvailabilityProjects extends React.PureComponent {
  static propTypes = {
    i18n: PropTypes.shape({ t: PropTypes.func.isRequired }).isRequired,
    hideClients: PropTypes.bool,
    isDesktopOrLarger: PropTypes.bool,
    onClickCalendar: PropTypes.func,
    projects: PropTypes.arrayOf(Project).isRequired,
    startOfWeek: momentObj.isRequired,
    endOfWeek: momentObj.isRequired,
  };

  static defaultProps = {
    isDesktopOrLarger: true,
    onClickCalendar: () => undefined,
    hideClients: false,
  };

  getKey = (week, index) => `W${week}-${index}`;

  getProjectRange = ({ startDate, endDate }) => {
    const projectStart = Moment(startDate);
    const projectEnd = getMomentExcludingMidnight(Moment(endDate));

    return {
      startSlot: projectStart.isBetween(this.props.startOfWeek, this.props.endOfWeek, undefined, '[]')
        ? projectStart.day()
        : 0,
      endSlot: projectEnd.isBetween(this.props.startOfWeek, this.props.endOfWeek, undefined, '[]')
        ? projectEnd.day() + 1
        : daysInWeek,
    };
  };

  getLaneColorStyle = (type, operationType) => {
    if (
      (type === projectTypeVessel || type === projectTypeBarge) &&
      (operationType === 'loading' || operationType === 'unloading')
    ) {
      return `vessel-${operationType}`;
    }
    return `other`;
  };

  getTimelineClassName = (type, operationType) => {
    if (!type) return `labor-availability-calendar__timeline`;
    return `labor-availability-calendar__timeline -${this.getLaneColorStyle(type, operationType)}`;
  };

  classList = (classNames, ...conditionals) =>
    classNames
      .concat(conditionals.filter(val => typeof val === 'string'))
      .join(' ')
      .replace(/\s+/g, ' ')
      .trim();

  handleClickCalendar = key => {
    if (!this.props.isDesktopOrLarger) {
      this.props.onClickCalendar(key);
    }
  };

  render() {
    const projects = this.props.projects.map(project => [project, this.getProjectRange(project)]);
    const timelines = createTimelineLanes(daysInWeek, projects);
    const week = this.props.startOfWeek.week();
    const remainingTimelines = timelines.splice(4);

    if (remainingTimelines.length === 1) timelines.push(remainingTimelines.pop());

    return (
      <ul className="labor-availability-calendar__projects">
        {timelines.map((lane, index) => (
          <li key={this.getKey(week, index)} className="labor-availability-calendar__lane">
            {lane.map(({ key, entity, size, isGap }) => {
              const width = `${size}%`;
              const { name, number, projectType, operationType } = entity || {};

              const projectLine = (
                <div className={this.classList`${this.getTimelineClassName(projectType, operationType)}`}>
                  <p className="labor-availability-calendar__project-label">{`${name} - ${number}`}</p>
                </div>
              );

              return isGap ? (
                <span key={key} className="labor-availability-calendar__timeline -gap" style={{ width }} />
              ) : (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <div onClick={() => this.handleClickCalendar(key)} key={key} id={key} style={{ width }}>
                  {this.props.isDesktopOrLarger ? (
                    <Tooltip
                      delay={100}
                      maxWidth={1200}
                      interactive
                      appendTo={document.body}
                      hideOnClick={false}
                      interactiveBorder={5}
                      className="monthly-calendar__tooltip"
                      content={
                        <LaborAvailabilityProjectTooltipContent
                          project={entity}
                          i18n={this.props.i18n}
                          hideClients={this.props.hideClients}
                        />
                      }
                      theme="dark">
                      {projectLine}
                    </Tooltip>
                  ) : (
                    projectLine
                  )}
                </div>
              );
            })}
          </li>
        ))}
        {remainingTimelines.length > 0 && (
          <li className="labor-availability-calendar__lane">
            <p className="labor-availability-calendar__project-label -more">{`${remainingTimelines.length} more`}</p>
          </li>
        )}
      </ul>
    );
  }
}
